<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Organisation</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="accent"
                  class="mr-2"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="addDepartmentForm.dialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-folder-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Add New Department</v-list-item-title>
                </v-list-item>
                <v-list-item @click="addPostForm.dialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-account-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Add New Post</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed color="warning" v-on="on">
                  <v-icon>mdi-key</v-icon>
                </v-btn>
              </template>
              <span>Post Permissions</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-treeview :items="departmentsTree" open-all item-key="key" hoverable>
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="item.children">{{
            open ? "mdi-folder-open" : "mdi-folder"
          }}</v-icon>
          <v-icon
            v-else-if="item.type === 'post' && item.team_member_id === null"
            color="red"
            >mdi-account-search</v-icon
          >
          <v-icon
            v-else-if="item.type === 'post' && item.team_member_id !== null"
            color="green"
            >mdi-account-check</v-icon
          >
        </template>
        <template v-slot:label="{ item }">
          <span v-if="item.type === 'department'">
            <strong>{{ item.name }}</strong>
          </span>
          <span v-else>{{ item.title }}</span>
        </template>
        <template v-slot:append="{ item }">
          <span v-if="item.type === 'post' && item.team_member_id === null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="blue"
                  v-on="on"
                  class="mr-2"
                  @click="openEditPost(item.department_id, item.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Post</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="green"
                  v-on="on"
                  class="mr-2"
                  @click="openAssignPost(item.department_id, item.id)"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>Assign User to Post</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="red"
                  v-on="on"
                  @click="
                    archivePostDialog = true;
                    archivePostData = item;
                  "
                >
                  <v-icon>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>Archive Post</span>
            </v-tooltip>
          </span>
          <v-tooltip
            bottom
            v-if="item.type === 'post' && item.team_member_id !== null"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                color="blue"
                v-on="on"
                @click="
                  openEditPosting(item.department_id, item.id, item.posting_id)
                "
              >
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Posting</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.type === 'department'">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                color="blue"
                v-on="on"
                @click="openEditDepartment(item.id)"
              >
                <v-icon>mdi-folder-edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Department</span>
          </v-tooltip>
        </template>
      </v-treeview>
    </v-container>
    <v-dialog v-model="addDepartmentForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-form @submit.prevent="addDepartment" method="post">
          <v-card-title class="headline">Add New Department</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5 grey lighten-4">
            <v-text-field
              label="Name"
              type="text"
              v-model="addDepartmentForm.name"
              outlined
              background-color="white"
              required
              :error="addDepartmentForm.errors.hasOwnProperty('name')"
              :error-messages="addDepartmentForm.errors['name']"
            ></v-text-field>

            <v-autocomplete
              :disabled="isUpdating"
              label="Parent Department"
              :items="departments"
              v-model="addDepartmentForm.parent_department"
              outlined
              background-color="white"
              item-text="name"
              item-value="id"
              :return-object="false"
              :error="
                addDepartmentForm.errors.hasOwnProperty('parent_department')
              "
              :error-messages="addDepartmentForm.errors['parent_department']"
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="addDepartmentForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              type="submit"
              depressed
              :loading="addDepartmentForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPostForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-form @submit.prevent="addPost" method="post">
          <v-card-title class="headline">Add New Post</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5 grey lighten-4">
            <v-text-field
              label="Name"
              type="text"
              v-model="addPostForm.name"
              outlined
              background-color="white"
              required
              :error="addPostForm.errors.hasOwnProperty('name')"
              :error-messages="addPostForm.errors['name']"
            ></v-text-field>

            <v-autocomplete
              v-model="addPostForm.department_id"
              :disabled="isUpdating"
              :items="departments"
              label="Department"
              outlined
              background-color="white"
              item-text="name"
              item-value="id"
              :return-object="false"
              :error="addPostForm.errors.hasOwnProperty('department_id')"
              :error-messages="addPostForm.errors['department_id']"
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addPostForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              type="submit"
              depressed
              :loading="addPostForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assignPostForm.dialog"
      scrollable
      max-width="600px"
      @click:outside="resetAssignPost"
    >
      <v-card>
        <v-card-title class="headline">Assign Staff Member</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form
            @submit.prevent="assignPost"
            method="post"
            id="assign-post-form"
          >
            <v-autocomplete
              v-model="assignPostForm.fields.team_member_id"
              :disabled="isUpdating"
              :items="unassignedTeamMembers"
              label="Staff Member"
              outlined
              background-color="white"
              item-text="full_name"
              item-value="id"
              :return-object="false"
              :error="assignPostForm.errors.hasOwnProperty('team_member_id')"
              :error-messages="assignPostForm.errors['team_member_id']"
            ></v-autocomplete>

            <v-menu
              v-model="assignPostForm.startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="assignStartDateFormatted"
                  label="Start Date"
                  outlined
                  background-color="white"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error="assignPostForm.errors.hasOwnProperty('start_date')"
                  :error-messages="assignPostForm.errors['start_date']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="assignPostForm.fields.start_date"
                @input="assignPostForm.startDatePicker = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              label="Capacity"
              v-model="assignPostForm.fields.capacity"
              type="number"
              suffix="%"
              outlined
              background-color="white"
              :error="assignPostForm.errors.hasOwnProperty('capacity')"
              :error-messages="assignPostForm.errors['capacity']"
            ></v-text-field>
            <v-text-field
              label="Productivity"
              v-model="assignPostForm.fields.product"
              type="number"
              suffix="%"
              outlined
              background-color="white"
              :error="assignPostForm.errors.hasOwnProperty('product')"
              :error-messages="assignPostForm.errors['product']"
            ></v-text-field>
            <v-locked-text-field
              label="Salary"
              v-model="assignPostForm.fields.salary"
              type="number"
              prefix="£"
              outlined
              background-color="white"
              :error="assignPostForm.errors.hasOwnProperty('salary')"
              :error-messages="assignPostForm.errors['salary']"
            ></v-locked-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetAssignPost">Close</v-btn>
          <v-btn
            color="accent"
            type="submit"
            depressed
            :loading="assignPostForm.loading"
            form="assign-post-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editPostingForm.dialog"
      scrollable
      max-width="600px"
      @click:outside="resetEditPosting"
    >
      <v-card>
        <v-card-title class="headline">Edit Posting</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form
            @submit.prevent="editPosting"
            method="post"
            id="edit-posting-form"
          >
            <v-menu
              v-model="editPostingForm.startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editPostingStartDateFormatted"
                  label="Start Date"
                  outlined
                  background-color="white"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error="editPostingForm.errors.hasOwnProperty('start_date')"
                  :error-messages="editPostingForm.errors['start_date']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editPostingForm.fields.start_date"
                @input="editPostingForm.startDatePicker = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="editPostingForm.endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editPostingEndDateFormatted"
                  label="End Date"
                  outlined
                  background-color="white"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error="editPostingForm.errors.hasOwnProperty('end_date')"
                  :error-messages="editPostingForm.errors['end_date']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editPostingForm.fields.end_date"
                @input="editPostingForm.endDatePicker = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              label="Capacity"
              v-model="editPostingForm.fields.capacity"
              type="number"
              suffix="%"
              outlined
              background-color="white"
              :error="editPostingForm.errors.hasOwnProperty('capacity')"
              :error-messages="editPostingForm.errors['capacity']"
            ></v-text-field>
            <v-text-field
              label="Productivity"
              v-model="editPostingForm.fields.product"
              type="number"
              suffix="%"
              outlined
              background-color="white"
              :error="editPostingForm.errors.hasOwnProperty('product')"
              :error-messages="editPostingForm.errors['product']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetEditPosting">Close</v-btn>
          <v-btn
            color="accent"
            type="submit"
            depressed
            :loading="editPostingForm.loading"
            form="edit-posting-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editPostForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-form @submit.prevent="editPost" method="post">
          <v-card-title class="headline">Edit Post</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5 grey lighten-4">
            <v-text-field
              label="Name"
              type="text"
              v-model="editPostForm.name"
              outlined
              background-color="white"
              required
            ></v-text-field>

            <v-autocomplete
              v-model="editPostForm.department_id"
              outlined
              background-color="white"
              :disabled="isUpdating"
              :items="departments"
              label="Department"
              item-text="name"
              item-value="id"
              :return-object="false"
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="editPostForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              type="submit"
              depressed
              :loading="editPostForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDepartmentForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-form @submit.prevent="editDepartment" method="post">
          <v-card-title class="headline">Edit Department</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5 grey lighten-4">
            <v-text-field
              label="Name"
              type="text"
              v-model="editDepartmentForm.name"
              outlined
              background-color="white"
              required
              :error="editDepartmentForm.errors.hasOwnProperty('name')"
              :error-messages="editDepartmentForm.errors['name']"
            ></v-text-field>
            <v-autocomplete
              :disabled="isUpdating"
              label="Parent Department"
              :items="editDepartmentForm.otherDepartments"
              v-model="editDepartmentForm.parent_department"
              outlined
              background-color="white"
              item-text="name"
              item-value="id"
              clearable
              :return-object="false"
              :error="
                editDepartmentForm.errors.hasOwnProperty('parent_department')
              "
              :error-messages="editDepartmentForm.errors['parent_department']"
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editDepartmentForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              type="submit"
              depressed
              :loading="editDepartmentForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="archivePostDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Post</v-card-title>
        <v-card-text>
          Are you sure you want to delete
          {{ archivePostData ? archivePostData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archivePostDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archivePostLoading"
            @click="archivePost(archivePostData.id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Team",
          disabled: false,
          to: { name: "module-team" },
          exact: true,
        },
        {
          text: "Organisation",
          disabled: true,
        },
      ],
      isUpdating: false,
      addDepartmentForm: {
        dialog: false,
        name: null,
        parent_department: null,
        loading: false,
        errors: {},
      },
      addPostForm: {
        dialog: false,
        name: null,
        department: null,
        loading: false,
        errors: {},
      },
      assignPostForm: {
        startDatePicker: false,
        dialog: false,
        fields: {
          team_member_id: null,
          start_date: null,
          capacity: null,
          product: null,
          salary: null,
        },
        loading: false,
        errors: {},
      },
      editDepartmentForm: {
        dialog: false,
        name: null,
        parent_department: null,
        loading: false,
        errors: {},
      },
      editPostForm: {
        dialog: false,
        name: null,
        department_id: null,
        loading: false,
        errors: {},
      },
      editPostingForm: {
        dialog: false,
        startDatePicker: false,
        endDatePicker: false,
        fields: {
          start_date: null,
          end_date: null,
          capacity: null,
          product: null,
        },
        loading: false,
        errors: {},
      },
      archivePostDialog: false,
      archivePostLoading: false,
      archivePostData: null,
    };
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },

  computed: {
    departments() {
      return this.$store.state.team["departments"];
    },

    departmentsTree() {
      let departments = this.$store.state.team["departments"];
      departments = this.formatDepartmentsWithPosts(departments);
      departments = this.buildDepartmentTree(departments);

      return departments;
    },

    unassignedTeamMembers() {
      return this.$store.state.team["unassignedTeamMembers"];
    },

    postings() {
      return this.$store.state.team["postings"];
    },

    assignStartDateFormatted() {
      return this.formatDate(this.assignPostForm.fields.start_date);
    },

    editPostingStartDateFormatted() {
      return this.formatDate(this.editPostingForm.fields.start_date);
    },

    editPostingEndDateFormatted() {
      return this.formatDate(this.editPostingForm.fields.end_date);
    },
  },

  methods: {
    archivePost: function(id) {
      const appId = this.$route.params.id;
      this.archivePostLoading = true;
      this.$store
        .dispatch("team/archivePost", {
          appId,
          id,
        })
        .then(() => {
          this.archivePostData = null;
          this.archivePostLoading = false;
          this.archivePostDialog = false;
        });
    },

    addDepartment: function() {
      const appId = this.$route.params.id;
      const name = this.addDepartmentForm.name;
      const parent_department = this.addDepartmentForm.parent_department;
      this.addDepartmentForm.loading = true;

      this.$store
        .dispatch("team/addDepartment", {
          appId,
          name,
          parent_department,
        })
        .then(() => {
          this.addDepartmentForm = {
            dialog: false,
            name: null,
            parent_department: null,
            loading: false,
            errors: {},
          };
        })
        .catch(err => {
          this.addDepartmentForm.errors = err.response.data.errors;
          this.addDepartmentForm.loading = false;
        });
    },

    addPost: function() {
      const appId = this.$route.params.id;
      const name = this.addPostForm.name;
      const department = this.addPostForm.department_id;
      this.addPostForm.loading = true;

      this.$store
        .dispatch("team/addPost", {
          appId,
          name,
          department,
        })
        .then(() => {
          this.addPostForm = {
            dialog: false,
            name: null,
            department: null,
            loading: false,
            errors: {},
          };
        })
        .catch(err => {
          this.addPostForm.errors = err.response.data.errors;
          this.addPostForm.loading = false;
        });
    },

    openEditDepartment: function(id) {
      const department = this.departments.find(c => c.id === id);

      const otherDepartments = this.departments.filter(
        department => department.id !== id
      );

      this.editDepartmentForm = {
        dialog: true,
        otherDepartments: otherDepartments,
        id: id,
        departmentId: department.id,
        name: department.name,
        parent_department: department.parent_department,
        loading: false,
        errors: {},
      };
    },

    openEditPost: function(department_id, id) {
      const department = this.departments.find(c => c.id === department_id);
      let post = department.posts.find(posts => posts.id === id);

      this.editPostForm = {
        dialog: true,
        id: id,
        name: post.name,
        department_id: post.department_id,
        postId: post.id,
        loading: false,
        errors: {},
      };
    },

    openAssignPost: function(department_id, id) {
      const department = this.departments.find(c => c.id === department_id);
      const post = department.posts.find(posts => posts.id === id);

      this.assignPostForm.id = id;
      this.assignPostForm.postId = post.id;
      this.assignPostForm.dialog = true;
    },

    assignPost: function() {
      const appId = this.$route.params.id;
      const postId = this.assignPostForm.postId;
      this.assignPostForm.loading = true;

      this.$store
        .dispatch("team/addPosting", {
          appId,
          fields: this.assignPostForm.fields,
          postId,
        })
        .then(() => {
          this.resetAssignPost();
        })
        .catch(err => {
          this.assignPostForm.errors = err.response.data.errors;
          this.assignPostForm.loading = false;
        });
    },

    resetAssignPost() {
      this.assignPostForm = {
        startDatePicker: false,
        dialog: false,
        fields: {
          team_member_id: null,
          start_date: null,
          capacity: null,
          product: null,
          salary: null,
        },
        loading: false,
        errors: {},
      };
    },

    editPost: function() {
      const appId = this.$route.params.id;

      const postId = this.editPostForm.postId;
      const department_id = this.editPostForm.department_id;
      const name = this.editPostForm.name;

      this.editPostForm.loading = true;

      this.$store
        .dispatch("team/editPost", {
          appId,
          postId,
          department_id,
          name,
        })
        .then(() => {
          this.editPostForm = {
            dialog: false,
            id: null,
            name: null,
            department_id: null,
            postId: null,
            loading: false,
            errors: {},
          };
        })
        .catch(err => {
          this.editPostForm.errors = err.response.data.errors;
          this.editPostForm.loading = false;
        });
    },

    openEditPosting: function(department_id, post_id, id) {
      const department = this.departments.find(c => c.id === department_id);
      const post = department.posts.find(c => c.id === post_id);
      const posting = post.postings.find(c => c.id === id);

      this.editPostingForm.fields.start_date = posting.start_date;
      this.editPostingForm.fields.capacity = posting.capacity;
      this.editPostingForm.fields.product = posting.product;
      this.editPostingForm.id = id;
      this.editPostingForm.team_member_id = posting.team_member_id;
      this.editPostingForm.postId = posting.post_id;
      this.editPostingForm.postingId = posting.id;
      this.editPostingForm.dialog = true;
    },

    resetEditPosting() {
      this.editPostingForm = {
        dialog: false,
        startDatePicker: false,
        editDatePicker: false,
        fields: {
          start_date: null,
          end_date: null,
          capacity: null,
          product: null,
        },
        loading: false,
        errors: {},
        id: null,
        team_member_id: null,
        postId: null,
        postingId: null,
      };
    },

    editPosting: function() {
      const appId = this.$route.params.id;
      const postingId = this.editPostingForm.postingId;
      const postId = this.editPostingForm.postId;
      const team_member_id = this.editPostingForm.team_member_id;

      this.editPostingForm.loading = true;

      this.$store
        .dispatch("team/editPosting", {
          appId,
          postId,
          postingId,
          team_member_id,
          fields: this.editPostingForm.fields,
        })
        .then(() => {
          this.resetEditPosting();
        })
        .catch(err => {
          this.editPostingForm.errors = err.response.data.errors;
          this.editPostingForm.loading = false;
        });
    },

    editDepartment: function() {
      const appId = this.$route.params.id;
      const departmentId = this.editDepartmentForm.departmentId;
      const name = this.editDepartmentForm.name;
      const parent_department = this.editDepartmentForm.parent_department;

      this.editDepartmentForm.loading = true;

      this.$store
        .dispatch("team/editDepartment", {
          appId,
          departmentId,
          name,
          parent_department,
        })
        .then(() => {
          this.editDepartmentForm = {
            dialog: false,
            id: null,
            name: null,
            parent_department: null,
            loading: false,
            errors: {},
          };
        })
        .catch(err => {
          this.editDepartmentForm.errors = err.response.data.errors;
          this.editDepartmentForm.loading = false;
        });
    },

    formatDepartmentsWithPosts: function(departments) {
      return departments.map(department => ({
        ...department,
        key: "department-" + department.id,
        children: department.posts.map(post => ({
          ...post,
          title:
            post.postings.length > 0
              ? post.name.concat(
                  " - ",
                  post.postings[0].team_member.user.full_name
                )
              : post.name.concat(" - Empty"),
          type: "post",
          team_member_id:
            post.postings.length > 0 ? post.postings[0].team_member_id : null,
          posting_id: post.postings.length > 0 ? post.postings[0].id : null,
          key: "post-" + post.id,
        })),
      }));
    },

    buildDepartmentTree: function(departments, parentId = null) {
      return departments
        .filter(department => department.parent_department === parentId)
        .map(department => ({
          ...department,
          children: [
            ...department.children,
            ...this.buildDepartmentTree(departments, department.id),
          ],
          type: "department",
        }));
    },
  },
};
</script>
